import React from 'react'
import { graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import LayoutRu from '../components/layoutRu'
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SEORu from "../components/SEORu"

const SubjectTemplate = ({ data }) => {
  const {
    title,
    slug,
    summary: { summary },
    mainText,
    mainImage,
  } = data.subject
  // console.log("data:", data)
  // console.log("articles:", articles)
  const Bold = ({ children }) => <strong>{children}</strong>
  const Text = ({ children }) => <p>{children}</p>
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  }

  return (
    <LayoutRu>
      <SEORu title={title} description={summary} />
      <>
        <div>
          <h1>{title}</h1>
        </div>
        <div>
          {mainImage ?
            <Image fluid={mainImage.fluid} className="mainImg" alt={title} />
            : null
          }
          {documentToReactComponents(mainText.json, options)}
        </div>
      </>
    </LayoutRu>
  )
}

export const query = graphql`
  query($slug: String) {
    subject: contentfulSubjectRu(slug: { eq: $slug }) {
      title
      slug
      summary {
        summary
      }
      mainText {
        json
      }
      mainImage {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

export default SubjectTemplate
